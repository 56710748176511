import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const getCookieValue = (name) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

const aiConnectionString = decodeURIComponent(getCookieValue('ai_connectionString'));

if (aiConnectionString) {
    window.appInsights = new ApplicationInsights({
        config: {
            connectionString: aiConnectionString,
            disableInstrumentationKeyValidation: true
        }
    });

    window.appInsights.loadAppInsights();
    window.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview 
}